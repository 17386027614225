import { CellContext, flexRender } from "@tanstack/react-table";
import useTimezone from "../../../hooks/useTimezone";
import TableOverflowWrapper from "./TableOverflowWrapper";

export enum DateFormat {
    Date = "date",
    Time = "time",
    DateTime = "datetime",
    ShortDateTime = "shortdatetime",
    MonthYear = "monthyear",
}

interface TableDateCellProps<D extends object = Record<string, unknown>> extends CellContext<D, Date> {
    format?: DateFormat;
    noTimezone?: boolean;
    nullValue?: string;
}

const TableDateCell = <D extends object = Record<string, unknown>>({
    format = DateFormat.Date,
    noTimezone = false,
    nullValue = "",
    ...textCellProps
}: TableDateCellProps<D>) => {
    const { formatDate, formatMonthYear, formatTime, formatDateTime, formatShortDateTime } = useTimezone();
    const formatter = {
        [DateFormat.Date]: formatDate,
        [DateFormat.Time]: formatTime,
        [DateFormat.DateTime]: formatDateTime,
        [DateFormat.ShortDateTime]: formatShortDateTime,
        [DateFormat.MonthYear]: formatMonthYear,
    }[format];

    const value = textCellProps.cell.getValue();
    const formattedContent = value != null && formatter !== undefined ? formatter(value, { noTimezone }) : nullValue;

    return <TableOverflowWrapper>{flexRender(formattedContent, textCellProps.cell.getContext())}</TableOverflowWrapper>;
};

export default TableDateCell;
