import { Stack } from "@mui/material";
import { CellContext } from "@tanstack/react-table";
import { UserBasicDto, UserDto } from "api-shared";
import UserEntryWithPopup from "../../user/UserEntryWithPopup";

// User to display. If a number is passed, the user is resolved using the users prop

interface TableUserCellProps<D extends object = Record<string, unknown>> extends CellContext<D, UserBasicDto | number> {
    users?: UserDto[] | null;
}

const TableUserCell = <D extends object = Record<string, unknown>>({
    users,
    children,
    cell,
}: React.PropsWithChildren<TableUserCellProps<D>>) => {
    const value = cell.getValue();
    if (value == null) {
        return null;
    }

    if (typeof value === "number" && users == null) {
        // not able to resolve the user, show the id instead
        return (
            <Stack direction="row" alignItems="center" spacing={1}>
                <span>{value}</span>
                {children}
            </Stack>
        );
    }
    const user = typeof value === "number" && users != null ? users.find((u) => u.id === value) : value;
    if (typeof user !== "object") {
        return null;
    }
    return (
        <Stack direction="row" alignItems="center" spacing={1}>
            <UserEntryWithPopup inline disableGutters user={user} avatarProps={{ size: 23 }} />
            {children}
        </Stack>
    );
};

export default TableUserCell;
